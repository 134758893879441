/* MyEvent.css */
.page-container {
    display: flex;
}

/* Upravení šířky Sidebaru podle potřeby */
.sidebar {
    width: 250px;
}

.content-container {
    flex: 1; /* Obsazuje veškerý zbývající dostupný prostor vedle Sidebaru */
    padding: 20px;
    /* Další styly pro obsah komponenty MyEvent */
}
