/* Sidebar.css */
.sidebar {
    background: linear-gradient(orange, lightcoral);
    padding: 20px;
    width: 250px;
    height: 100vh;
    transition: width 0.3s ease;
    overflow-y: auto;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
}

.sidebar.collapsed {
    width: 70px;
}

.sidebar button {
    width: 100%;
    text-align: left;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 18px;
    color: white;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar button.collapsed {
    text-align: center;
}

.sidebar button.collapsed::before {
    content: '\2190';
}

.sidebar button:not(.collapsed):hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.sidebar button:not(.collapsed):active {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Zvýraznění aktuální položky */
.nav-link.active {
    background-color: rgba(255, 255, 255, 0.2);
}

.nav-link {
    color: white !important;
    display: flex;
    align-items: center;
    width: 100%;
}

.nav-link:hover,
.nav-link:active {
    background-color: rgba(255, 255, 255, 0.1);
}

.sidebar .nav-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
}

.sidebar .nav-text {
    margin-left: 15px;
    font-weight: bold;
}

.collapsed .nav-text {
    display: none;
}

/* Zvětšení ikon */
.sidebar .nav-link .svg-inline--fa {
    font-size: 1.8em;
}
