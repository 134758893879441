/* Layout.css */
.layout-container {
    display: flex;
    height: 100vh;
}

.collapsed .content-container {
    margin-left: 80px; /* Šířka zhruba odpovídající šířce zavřeného Sidebaru */
}

.content-container {
    flex-grow: 1;
    padding: 20px;
    transition: margin-left 0.3s ease; /* Přidání animace */
}

/* Styly pro Sidebar ponechte v Sidebar.css nebo kamkoliv jinam podle potřeby */
